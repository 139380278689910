import { session } from "./cache";
import { Token as TokenKey } from "./cacheKey";

export function getToken() {
  return session.get(TokenKey);
}

export function setToken(token) {
  return session.set(TokenKey, token);
}

export function removeToken() {
  return session.remove(TokenKey);
}

class user {
  constructor(userData) {
    Object.assign(this, userData);
  }

  hasRole(roleName) {
    return this.roles.indexOf(roleName) > -1;
  }

  get props() {
    if (typeof this.extendProperies == "object") return this.extendProperies;

    if (this._props) return this._props;

    let p = this.extendProperies;
    if (typeof p == "string" && this._props == null)
      this._props = JSON.parse(p);

    return this._props;
  }
}

export function createUser(userData) {
  return new user(userData);
}

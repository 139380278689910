import { RouteRecordRaw } from "vue-router";
import { getToken } from "@/utils/auth";
import router from "./router";
import store from "./store";

import { ElMessage } from "element-plus";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

// import {
//   getToken
// } from "@/utils/auth"; // get token from cookie
// import getPageTitle from "@/utils/get-page-title";

NProgress.configure({
  showSpinner: false,
}); // NProgress Configuration

const whiteList = ["/login", "/register", "/forgetpassword", "/auth-redirect"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  //document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken();

  if (hasToken) {
    if (to.path === "/login") {
      next();
      NProgress.done();
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      if (hasRoles) {
        next();
      } else {
        try {
          //await store.dispatch("settingPush/fetch");

          const { roles } = await store.dispatch("user/getInfo");

          // generate accessible routes map based on roles
          const accessRoutes: Array<RouteRecordRaw> = await store.dispatch(
            "permission/generateRoutes",
            roles
          );

          // dynamically add accessible routes
          accessRoutes.forEach((p) => {
            router.addRoute(p);
          });

          console.log(router.getRoutes());

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          //console.log(to);
          next({
            ...to,
            replace: true,
          });
        } catch (error) {
          // remove token and go to login page to re-login
          //await store.dispatch("user/resetToken");

          ElMessage.error(error || "Has Error");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import store from "../store";
import { getToken } from "@/utils/auth";

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  // withCredentials: true,
  timeout: 60 * 1000 * 60 * 12, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
    // console.log(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  (response) => {
    /**
     * code为非20000是抛错 可结合自己业务进行修改
     */
    const res = response.data;
    if (res.code !== 20000) {
      if (res.message || res.Message) {
        let msg = res.message || res.Message;
        if (msg == "Network Error") {
          msg = "系统未知错误，请联系管理员";
        }
        ElMessage({
          message: msg,
          type: "error",
          duration: 5 * 1000,
        });
      }

      //非法操作
      if (res.code === 50002) {
        location.href = "/";
        return;
      }

      // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        ElMessageBox.confirm(
          "你已被登出，可以取消继续留在该页面，或者重新登录",
          "确定登出",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("logout").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      }

      if (res.code == 404) {
        return null;
      }
      return Promise.reject(res);
    } else {
      return res.data;
    }
  },
  (error) => {
    //console.log(error, error.s, test, error.config);
    //console.log(error, this);
    if (error.response) {
      let response = error.response;
      if (response.status == 401) {
        // ElMessage({
        //   type: "warning",
        //   message: "登录身份无效或过期，请重新登录！",
        // });
        store.dispatch("user/logout").then(() => {
          ElMessageBox.alert("登录身份无效或过期，请重新登录", "警告", {
            showClose: false,
            type: "error",
            confirmButtonText: "确定",
            callback: (action) => {
              location.reload(); // 为了重新实例化vue-router对象 避免bug
            },
          });
        });
        return Promise.reject("身份无效或过期");
      }

      if (response.status == 403) {
        console.log(error.response);
        ElMessage({
          type: "error",
          message: "非法操作！",
        });
        return Promise.reject("非法操作！");
      }

      if (response.status == 400 || response.status == 405) {
        const data = response.data || {};

        ElMessage({
          type: "error",
          message: data?.message ? data.message : "传输数据格式错误！",
        });
        return Promise.reject(data ? data : "传输数据格式错误！");
      }

      if (response.status == 404) {
        ElMessage({
          type: "error",
          message: "错误的访问地址",
        });
        return Promise.reject("错误的访问地址");
      }
    }
    //console.log(error.message,error.Message);
    let msg = error.message || error.Message;
    if (msg == "Network Error") {
      msg =
        "系统未知错误，请按F5刷新页面后重试，如果异常未恢复，请及时联系管理员！";
    }
    ElMessage({
      message: msg,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export const baseRequest = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  //withCredentials: true,
  timeout: 60 * 1000 * 60 * 12, // 请求超时时间
});

baseRequest.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// respone拦截器

baseRequest.interceptors.response.use(
  (response) => {
    //if(response.contett)
    if (response.data instanceof Blob) {
      try {
        let reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/gi;
        let result = reg.exec(
          response.headers["content-disposition"].split(";")[2]
        );
        let filename = decodeURIComponent(result[1]);
        if (filename.indexOf("UTF-8''") == 0) {
          filename = filename.replace("UTF-8''", "");
        }
        //response.data.filename = filename;
      } catch {
        console.log("error");
      }
    }

    return response.data;
  },
  (error) => {
    if (error.response) {
      let response = error.response;
      if (response.status == 401) {
        ElMessage({
          type: "warning",
          message: "登录身份无效或过期，请重新登录！",
        });
        store.dispatch("logout").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
        return Promise.reject(new Error("身份无效或过期"));
      }

      return Promise.reject(error.response.data);
    }
  }
);

export default service;

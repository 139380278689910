import { login, logout, getInfo } from "@/network/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import router, { resetRouter } from "@/router";

const empty = {
  id: "",
  name: "",
  roles: [],
  userType_Id: null,
  inRoles(...roles) {
    return this.roles.some((p) => roles.indexOf(p) > -1);
  },
};
const state = Object.assign({ token: getToken() }, empty);

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_DATA: (state, data) => {
    Object.assign(state, data);
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { code, password, logOnType } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: code.trim(), password: password, logOnType })
        .then((response) => {
          const { access_token } = response;
          commit("SET_TOKEN", access_token);
          setToken(access_token);
          resolve();
        })
        .catch((error) => {
          //console.log(error);
          reject(error);
        });
    });
  },

  initRoles({ commit, state }) {
    return new Promise((resolve, reject) => {
      try {
        commit("SET_DATA", state.token);
        resolve(state);
      } catch {
        reject("身份认证失败！");
      }
    });
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then((data) => {
          if (!data) {
            reject("认证失败，请重新登录！");
          }

          const [_, rawPayload] = state.token.split(".");
          const payload = JSON.parse(
            new Buffer(rawPayload, "base64").toString()
          );

          //console.log(payload);
          data.roles = Array.isArray(payload.role)
            ? payload.role
            : [payload.role];
          //console.log(data.roles)
          data.id = payload.id;
          commit("SET_DATA", data);

          //console.log(state);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_DATA", empty);
          removeToken();
          resetRouter();

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          //dispatch("tagsView/delAllViews", null, { root: true });

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_DATA", empty);
      removeToken();
      resolve();
    });
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise((resolve, reject) => {
      const token = role + "-token";

      commit("SET_TOKEN", token);
      setToken(token);

      dispatch("getInfo")
        .then(async ({ roles }) => {
          resetRouter();

          // generate accessible routes map based on roles
          const accessRoutes = await dispatch(
            "permission/generateRoutes",
            roles,
            {
              root: true,
            }
          );

          // dynamically add accessible routes
          router.addRoutes(accessRoutes);

          // reset visited views and cached views
          dispatch("tagsView/delAllViews", null, { root: true });

          resolve();
        })
        .catch((err) => reject(err));
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

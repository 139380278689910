import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
const router: RouteRecordRaw = {
  path: "/ProjectDo",
  component: Layout,
  name: "ProjectDoIndex",
  meta: { title: "项目监管", topMenu: true, icon: "el-icon-s-flag" },
  redirect: "/ProjectDo/StatusFinish",
  children: [
    {
      path: "/ProjectDo/FundRun",
      component: () => import("@/views/projectDo/fundRun.vue"),
      name: "ProjectDoFundRun",
      meta: {
        title: "资金执行情况",
        roles: [roles.prov, roles.city, roles.applyUnit],
      },
    },
    {
      path: "/ProjectDo/Status",
      component: () => import("@/views/projectDo/status.vue"),
      name: "ProjectDoStatus",
      meta: {
        title: "项目进度填报",
        roles: [roles.prov, roles.city, roles.applyUnit],
      },
    },
    {
      path: "/ProjectDo/StatusFinish",
      component: () => import("@/views/projectDo/statusFinishList.vue"),
      name: "ProjectDoStatusFinish",
      meta: {
        title: "项目验收",
        roles: [roles.prov, roles.city, roles.applyUnit, roles.reviewUnit],
      },
    },
  ],
};

export default router;

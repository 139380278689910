import request, { baseRequest } from "@/utils/request";
import { reactive, onMounted, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { copyFrom } from "./common";
import { ElMessage } from "element-plus";
import axios from "axios";

export function login({ username, password, logOnType }) {
  return baseRequest({
    baseURL: process.env.VUE_APP_BASE_API.substring(
      0,
      process.env.VUE_APP_BASE_API.length - 4
    ),
    url: "/connect/token",
    method: "post",
    data: `grant_type=password&username=${username}&password=${password}&client_id=webClient&client_secret=webClient-secret&logOnType=${logOnType}`,
  });
}

export function logout() {
  return Promise.resolve(true);
}

export function getInfo() {
  return request.get("/user/byToken");
}

export function useQuery(filter) {
  const data = reactive({
    list: [],
    count: 0,
  });

  function query(replaceFilter = null) {
    Object.assign(filter, replaceFilter ?? {});
    request
      .get(`/user/query/${filter.pageNum}`, {
        params: filter,
      })
      .then((res) => {
        data.list = res.list;
        data.count = res.count;
      });
  }

  onMounted(() => {
    query();
  });

  return { data, query };
}

const _userTypeDic = ref(null);

export const userTypeDic = () => {
  if (_userTypeDic.value == null)
    request.get(`/user/userType`).then((res) => {
      _userTypeDic.value = res;
    });
  return _userTypeDic;
};

export function useUser(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);
  const empty = {
    id: 0,
    name: null,
    isLocked: null,
    loginAccount: null,
    cellPhone: null,
    sort: null,
    lastLoginTime: null,
    userType_Id: null,
    city_ID: null,
    county_ID: null,
    isApproved: null,
  };
  const result = reactive(Object.assign({}, empty));

  function baseSave(idValue: number, req): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .put(`/user/change/${idValue}`, req)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({
          message: "操作成功！信息已变更。",
          type: "success",
        });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }
  function changePassword(idValue: number, changeRequest): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .put(`/user/changePassword/${idValue}`, changeRequest)
      .then((res) => {
        isLoading.value = false;
        if (res) {
          ElMessage.success({
            message: "操作成功！密码已修改。",
            type: "success",
          });
        } else {
          ElMessage.error({
            message: "操作失败！",
            type: "error",
          });
        }
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function resetPassword(idValue: number): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .put(`/user/resetPassword/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({
          message: "操作成功！密码已重置。",
          type: "success",
        });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function toggleLock(idValue: number): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .put(`/user/toggleLock/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function remove(idValue: number): void {
    isLoading.value = true;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    return request
      .delete(`/user/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      const cancelTokenSource = axios.CancelToken.source();
      cancel.value = cancelTokenSource;
      return request
        .get(`/user/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          copyFrom(result, empty, res);
          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save(config = null) {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (id.value) {
      ps = request
        .put(`/user/${id.value}`, result, config)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/user`, result, config)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  return [
    isLoading,
    result,
    find,
    save,
    resetPassword,
    toggleLock,
    remove,
    changePassword,
    baseSave,
  ];
}

export function useRegiste(refForm: Ref<any>) {
  const isSubmiting = ref(false);
  const $router = useRouter();
  const registe = () => {
    refForm.value.validate((valid) => {
      isSubmiting.value = true;
      if (valid) {
        request
          .post(`/register`, refForm.value.model)
          .then((p) => {
            //isSubmiting.value = false;
            ElMessage.success({
              message: "信息已提交，请等待系统审核！审核结果将以短信形式通知！",
              type: "success",
              duration: 8000,
            });
            setTimeout(() => {
              $router.push("/login");
            }, 2000);
          })
          .catch(() => {
            isSubmiting.value = false;
          });
      } else {
        isSubmiting.value = false;
        return false;
      }
    });
  };

  return [isSubmiting, registe];
}

export const checkPassword = (id, password) => {
  return request.put(`/user/checkPassword/${id}/${password}`);
};

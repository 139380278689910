import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import installElementPlus from "./plugins/element";
import "./assets/style/v1.0.scss";
import "@/permission"; // permission control
import { drag } from "./directive/drag.ts";
const app = createApp(App);
installElementPlus(app);
app.directive("drag", drag);
app.use(store).use(router).mount("#app");

export default app;

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";

const router: RouteRecordRaw = {
  path: "/Analysis",
  component: Layout,
  meta: { title: "数据统计", topMenu: true, icon: "el-icon-s-claim" },
  name: "analysis",
  redirect: "/Analysis/YearSys",
  children: [
    {
      path: "/Analysis/YearSys",
      component: () => import("@/views/analysis/yearSys.vue"),
      name: "AnalysisYearSys",
      meta: {
        title: "按年度系统统计表",
      },
    },
    {
      path: "/Analysis/Year",
      component: () => import("@/views/analysis/year.vue"),
      name: "AnalysisYear",
      meta: {
        title: "资金安排年度数据",
        roles: [roles.prov],
      },
    },

    {
      path: "/Analysis/Area",
      component: () => import("@/views/analysis/area.vue"),
      name: "AnalysisArea",
      meta: {
        title: "地区数据",
        roles: [roles.prov],
      },
    },
    {
      path: "/Analysis/All",
      component: () => import("@/views/analysis/all.vue"),
      name: "AnalysisAll",
      meta: {
        title: "综合查询",
        roles: [roles.prov],
      },
    },
  ],
};

export default router;

import { watch, ref, computed } from "vue";
export const copy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const copyFrom = (obj, empty = null, fromObj = null) => {
  if (empty == null && fromObj == null) {
    return copy(obj);
  } else
    return Object.assign(
      obj,
      copy(empty),
      fromObj == null ? {} : copy(fromObj)
    );
};

export const clearFrom = (obj, init = {}, fromObj = {}) => {
  Object.keys(obj).map((key) => {
    delete obj[key];
  });
  Object.assign(obj, copy(init), copy(fromObj));
};

export const useCheckDataChange = (data) => {
  const isDataChanged = ref(false);
  const newValue = ref(null);
  const oldValue = ref(null);
  watch(
    computed(() => JSON.parse(JSON.stringify(data))),
    (v, oldVal) => {
      //console.log("aaa");
      //console.log(v ,oldVal);
      newValue.value = v;
      oldValue.value = oldVal;
      //console.log(Object.entries(newValue.value).toString(),Object.entries(oldValue.value).toString());
    },
    { deep: true }
  );

  const checkChange = () => {
    isDataChanged.value =
      Object.entries(newValue.value).toString() !=
      Object.entries(oldValue.value).toString();

    return isDataChanged.value;
  };

  const setChangedEqual = () => {
    oldValue.value = newValue.value;
    //console.log(oldValue.value == newValue.value);
  };
  return { checkChange, setChangedEqual, oldValue };
};

<template>
  <div class="user-wrapper">
    <Setting :setting="manageSetting" @change="change" v-if="manageSetting.visible"></Setting>
    <el-dropdown trigger="click" @command="handleCommand">
      <div class="user-infor">
        <!-- <div class="avatar">
          <img :src="user.headThumb" />
        </div> -->
        <div class="username">
          {{ user.name }}
          <i class="el-icon-caret-bottom"></i>
        </div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <!-- <el-dropdown-item icon="el-icon-user-solid">个人中心</el-dropdown-item>-->
          <el-dropdown-item icon="el-icon-setting" command="setting">设置</el-dropdown-item>
          <el-dropdown-item icon="el-icon-switch-button" command="logout">退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script>
import Setting from "@/views/shared/setting.vue";
import { defineComponent, inject, reactive } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    Setting,
  },
  name: "user",
  setup() {
    const store = useStore();
    const user = inject("user");
    const manageSetting = reactive({
      id: null,
      visible: false,
    });
    const handleCommand = (command) => {
      if (command == "logout") logout();
      else {
        manageSetting.visible = true;
        manageSetting.id = user.id;
        // this.$router.push("/account/setting");
      }
    };
    const logout = () => {
      store.dispatch("user/logout").then(() => {
        location.reload(); // 为了重新实例化vue-router对象 避免bug
      });
    };

    const change = (item) => {
      console.log(item);
    };

    return { user, manageSetting, handleCommand, logout, change };
  },
});
</script>

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
import store from "@/store/index";

const router: RouteRecordRaw = {
  path: "/FundAmountReview",
  component: Layout,
  name: "FundAmountReview",
  meta: {
    title: "预算评审",
    topMenu: true,
    icon: "el-icon-money",
    roles: [roles.reviewUnit],
  },
  children: [
    {
      path: "",
      component: () => import("@/views/fund/amountReviewList.vue"),
      name: "FundAmountReviewIndex",
      meta: {
        title: "预算评审",
      },
    },
  ],
};

export default router;

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
import store from "@/store/index";

const router: RouteRecordRaw = {
  path: "/Fund",
  component: Layout,
  redirect: "/Fund/Review",
  name: "FundIndex",
  meta: {
    title: "专项资金项目",
    topMenu: true,
    icon: "el-icon-money",
    roles: [roles.applyUnit, roles.city, roles.prov],
  },
  children: [
    // {
    //   path: "/Fund/ProjectLib",
    //   component: () => import("@/views/fund/libIndex.vue"),
    //   name: "projectFundLib",
    //   meta: {
    //     title: "项目库",
    //     roles: [roles.applyUnit, roles.city, roles.prov],
    //   },
    // },
    {
      path: "/Fund/Review",
      component: () => import("@/views/fund/reviewList.vue"),
      name: "ProjectFundReview",
      meta: {
        title: () => {
          return store.getters.user.inRoles(roles.prov)
            ? "项目评审"
            : "项目申报";
        },
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    // {
    //   path: "/Fund/Performance",
    //   component: () => import("@/views/fund/reviewList.vue"),
    //   name: "ProjectFundJiXiao",
    //   meta: {
    //     title: () => {
    //       return store.getters.user.inRoles(roles.prov)
    //         ? "绩效评价"
    //         : "绩效填报";
    //     },
    //     roles: [roles.applyUnit, roles.city, roles.prov],
    //   },
    // },
    {
      path: "/Fund/Manage",
      component: () => import("@/views/fund/fundFile.vue"),
      name: "ProjectFundApplyFile",
      meta: {
        title: "资金文件",
        roles: [roles.city, roles.fundCountyManager],
      },
    },
    // {
    //   path: "/Fund/Review?tab=giveFund",
    //   component: () => import("@/views/fund/reviewList.vue"),
    //   name: "ProjectFundGive",
    //   meta: { title: "资金安排", roles: [roles.giveProjectFund] },
    // },
    {
      path: "/Fund/Manage",
      component: () => import("@/views/fund/fundFile.vue"),
      name: "ProjectFundManage",
      meta: { title: "各地年度申请", roles: [roles.prov] },
    },
  ],
};

export default router;

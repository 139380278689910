<template>
  <div class="app-wrapper">
    <CHeader />

    <section class="main" style="padding: 20px">
      <Crumbs v-show="crumbs"></Crumbs>
      <router-view />
    </section>
    <!-- <section class="main" >
      <router-view />
    </section> -->
  </div>
</template>

<script>
import { defineComponent, provide } from "vue"
import Header from "@/views/layout/Header.vue"
import Crumbs from "@/views/layout/header/Crumbs.vue"
import { useStore } from "vuex"
export default defineComponent({
  components: {
    CHeader: Header,
    Crumbs,
  },
  props: {
    crumbs: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore()
    const user = store.getters.user
    provide("user", user)
    return {}
  },
})
</script>

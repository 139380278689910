import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
import store from "@/store/index";

const router: RouteRecordRaw = {
  path: "/PermissionPlan",
  component: Layout,
  redirect: "/PermissionPlan/Index",
  name: "PermissionPlan",
  meta: {
    title: "行政许可",
    topMenu: true,
    icon: "el-icon-document",
    roles: [roles.applyUnit, roles.city, roles.prov],
  },
  children: [
    {
      path: "/PermissionPlan/Index",
      component: () => import("@/views/permissionPlan/index.vue"),
      name: "PermissionPlanIndex",
      meta: {
        title: "行政许可",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
    },
    {
      path: "/PermissionPlan/Manage/:id?",
      component: () => import("@/views/permissionPlan/manage.vue"),
      name: "PermissionPlanManage",
      meta: {
        hidden: true,
        title: "行政许可详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
      props: true,
    },
    {
      path: "/PermissionPlan/Detail/:id?",
      component: () => import("@/views/permissionPlan/manage.vue"),
      name: "PermissionPlanDetail",
      meta: {
        hidden: true,
        title: "行政许可详情",
        roles: [roles.applyUnit, roles.city, roles.prov],
      },
      props: true,
    },
  ],
};

export default router;

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/systemManage/layout";
import RouterView from "@/views/layout/Router-View.vue";
import roles from "@/types/roles";
const router: RouteRecordRaw = {
  path: "/SystemManage",
  component: Layout,
  name: "SystemManageIndex",
  meta: {
    title: "系统管理",
    topMenu: true,
    // link: true,
    icon: "el-icon-s-tools",
    roles: [
      roles.admin,
      roles.userManage,
      //, roles.city
    ],
  },

  children: [
    {
      meta: { title: "概览", hidden: true },
      path: "/SystemManage",
      component: () => import("@/views/systemManage/dashboard.vue"),
    },
    {
      meta: {
        title: "用户管理",
        icon: "el-icon-user-solid",
        roles: [
          roles.admin,
          roles.userManage,
          // , roles.city
        ],
      },
      path: "/SystemManage/User",
      component: () => import("@/views/systemManage/user/index"),
    },
    // {
    //   path: "/SystemManage/CulturalUnit",
    //   component: RouterView,
    //   meta: {
    //     title: "文保单位管理",
    //     icon: "el-icon-office-building",
    //     roles: [roles.admin],
    //   },
    //   children: [
    //     {
    //       meta: { title: "文保单位" },
    //       path: "/SystemManage/CulturalUnit/index",
    //       component: () => import("@/views/systemManage/heritageUnit/index"),
    //     },
    //     {
    //       meta: { title: "其他文保单位" },
    //       path: "/SystemManage/CulturalUnit/elseIndex",
    //       component: () =>
    //         import("@/views/systemManage/heritageUnit/elseIndex"),
    //     },
    //   ],
    // },
    {
      meta: {
        title: "资金批次管理",
        icon: "el-icon-edit",
        roles: [roles.admin],
      },
      path: "/SystemManage/FundBatch",
      component: () => import("@/views/systemManage/fundBatch/index"),
    },
    {
      meta: {
        title: "批文类型管理",
        icon: "el-icon-edit",
        roles: [roles.admin],
      },
      path: "/SystemManage/CodeFileNum",
      name:"/SystemManage/CodeFileNum",
      component: () => import("@/views/systemManage/codeFileNum/index.vue"),
    },
    // {
    //   path: "/SystemManage/BaseData",
    //   component: () => import("@/views/systemManage/user/applyUnitIndex"),
    //   meta: { title: "基础数据管理", icon: "el-icon-notebook-1" },
    //   children: [
    //     {
    //       meta: { title: "行政区划" },
    //       path: "/SystemManage/BaseData/ChinaArea",
    //       component: () => import("@/views/systemManage/user/applyUnitIndex"),
    //     },
    //     {
    //       meta: { title: "常用字典表" },
    //       path: "/SystemManage/BaseData/Dic",
    //       component: () => import("@/views/systemManage/user/applyUnitIndex"),
    //     },
    //   ],
    // },
  ],
};
export default router;

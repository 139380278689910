<template>
  <el-affix>
    <el-menu ref="elMenu" class="el-menu-vertical-demo" router :collapse="collapsed" :default-openeds="opened"
      :default-active="defaultActive" mode="horizontal" :collapse-transition="false">
      <el-menu-item index="/" key="/">
        <i class="el-icon-s-home"></i>
        <template #title>
          <span>首页</span>
        </template>
      </el-menu-item>
      <template v-for="(item, index) in routes">
        <template v-if="!item.meta.hidden && inRoles(item.meta.roles)">
          <el-menu-item :index="$router.resolve(item.path).href" v-if="!item.children" :key="item.path">
            <i :class="item.meta.icon"></i>
            <template #title>
              <span>{{ title(item.meta.title) }}</span>
            </template>
          </el-menu-item>
          <el-submenu :index="'sm-' + index" v-else-if="item.children.filter(p => !p.meta.hidden).length > 1"
            :key="item.path" @click="navCheck(item)">
            <template #title>
              <i :class="item.meta.icon"></i>
              <span>{{ title(item.meta.title) }}</span>
            </template>
            <template v-for="(child, cindex) in item.children">
              <template v-if="!child.meta.hidden && inRoles(child.meta.roles)">
                <el-menu-item :index="$router.resolve(child.path).href" v-if="!child.children" :key="child.path">
                  <i :class="child.meta.icon"></i>
                  <span>{{ title(child.meta.title) }}</span>
                </el-menu-item>
                <el-submenu :index="'sm' + index + '-' + cindex" v-else :key="child.path">
                  <template #title>
                    <i :class="child.meta.icon"></i>
                    <span>{{ child.meta.title }}</span>
                  </template>
                  <template v-for="subChild in child.children">
                    <el-menu-item :index="$router.resolve(subChild.path).href" :key="subChild.path" v-if="!subChild.meta.hidden && inRoles(subChild.meta.roles)
      " :route="subChild.props
      ? { name: subChild.name, params: {} }
      : { path: subChild.path }
      ">
                      <i :class="subChild.meta.icon"></i>
                      <span>{{ title(subChild.meta.title) }}</span>
                    </el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </template>
          </el-submenu>
          <el-menu-item :index="item.path" v-else-if="item.children.filter(p => !p.meta.hidden).length == 1"
            :key="item.path">
            <i :class="item.meta.icon"></i>
            <template #title>
              <span>{{ title(item.meta.title) }}</span>
            </template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </el-affix>
</template>

<script>
export default {
  name: "cmenu",
  inject: ["user"],
  methods: {
    inRoles(roles) {
      if (roles == undefined) return true;
      return (
        roles.filter((p) => this.user.roles.some((d) => d == p)).length > 0
      );
    },
    navCheck(item) {
      if (item.meta.link) {
        this.$router.push(this.$router.resolve(item.path).href);
      }
    },
    title(titleArg) {
      if (titleArg instanceof Function) {
        return titleArg();
      } else {
        return titleArg;
      }
    },

  },
  computed: {

    routes() {
      // console.log(this.$router
      //   .getRoutes().filter((p) => p.meta.topMenu == true));
      return this.$router
        .getRoutes()
        .filter((p) => p.meta.topMenu == true)
    },
    collapsed() {
      return this.$store.state.collapsed;
    },
    defaultActive() {
      return this.$route.matched[this.$route.matched.length - 1].path;
    },
    opened() {
      // this.user.roles.forEach(p => {
      //   this.$router.options.routes.map(
      //     (d,index) =>
      //       if(!d.hidden && d.meta && d.meta.roles && d.meta.roles.indexOf(p) > -1)
      //       {

      //       }
      //   );
      // });

      return [];
    },
  },
};
</script>
<style>
.el-menu-item span,
.el-submenu__title span {
  font-size: 13pt;
}
</style>
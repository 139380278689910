import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
const router: RouteRecordRaw = {
  path: "/HistoryProject",
  component: Layout,
  name: "HistoryProjectIndex",
  meta: { title: "历史项目", topMenu: true, icon: "el-icon-document-copy" },

  children: [
    {
      path: "/HistoryProject/Fund",
      component: () => import("@/views/historyProject/reviewList.vue"),
      name: "HistoryProjectFund",
      meta: {
        title: "资金项目",
        roles: [roles.prov],
      },
    },
    {
      path: "/HistoryProject/Fund",
      component: () => import("@/views/historyProject/reviewList.vue"),
      name: "HistoryProjectFund",
      meta: {
        hidden: true,
        title: "资金项目详情",
        roles: [roles.prov],
      },
    },
    // {
    //   path: "/HistoryProject/Setup",
    //   component: () => import("@/views/historyProject/reviewList.vue"),
    //   name: "HistoryProjectSetup",
    //   meta: {
    //     title: "计划书",
    //     roles: [roles.prov],
    //   },
    // },
    // {
    //   path: "/HistoryProject/Plan",
    //   component: () => import("@/views/historyProject/reviewList.vue"),
    //   name: "HistoryProjectPlan",
    //   meta: {
    //     title: "方案",
    //     roles: [roles.prov],
    //   },
    // },
  ],
};

export default router;

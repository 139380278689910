import { ref, Ref, watch } from "vue";
import request from "@/utils/request";

const chinaArea = ref([]);
const useChinaAreaDic = async () => {
  if (chinaArea.value.length == 0) {
    const p = await request("ChinaArea/jsonData");
    chinaArea.value = p;
  }
  return chinaArea;
};

// const _fundCountries = ref([]);
// export const fundCountries = () => {
//   if(_fundCountries)
//   request("ChinaArea/fundCountries").then((p) => {
//     _fundCountries.value = p;
//   });
// };

const _fundCountries = ref(null);
export const usefundCountriesDic = () => {
  if (_fundCountries.value == null)
    request.get(`/ChinaArea/fundCountries`).then((res) => {
      _fundCountries.value = res;
    });
  return _fundCountries;
};

export function useCityRelTown(
  cityValue: Ref,
  townValue: Ref,
  isFilter = true
) {
  const city = ref([]);
  const town = ref([]);
  const doFn = () => {
    if (city.value.length == 0) {
      city.value = chinaArea.value.filter(
        (p) => (isFilter == null || p.isSelect == isFilter) && (p.extend == null || !p.extend.hidden)
      );
    }
    town.value = chinaArea.value.find((d) => d.id == cityValue.value)?.children;
    //console.log(town.value, townValue.value);
    if (
      !town.value?.some((p) => p.id == townValue.value) ||
      town.value == null ||
      town.value.length == 0
    ) {
      townValue.value = null;
    }
  };
  watch(
    cityValue,
    (v) => {
      if (chinaArea.value.length != 0) {
        doFn();
      } else {
        useChinaAreaDic().then(() => {
          doFn();
        });
      }
    },
    { immediate: true }
  );

  return [city, town];
}

<template>
  <div class="app-wrapper">
    <CHeader />
    <el-container class="container">
      <el-aside width="240" class="sider">
        <el-scrollbar class="scrollbar">
          <div
            style="
              text-align: left;
              padding-top: 5px;
              padding-left: 22px;
              border-right: solid 1px #e6e6e6;
            "
          >
            <el-tooltip
              :content="isCollapse ? '展开菜单' : '收起菜单'"
              placement="right"
            >
              <i
                :class="[
                  'el-icon',
                  isCollapse ? 'el-icon-s-fold' : 'el-icon-s-unfold',
                ]"
                style="
                  font-size: 16pt;
                  display: block;
                  cursor: pointer;
                  color: #409eff;
                "
                @click="isCollapse = !isCollapse"
              ></i>
            </el-tooltip>
          </div>
          <el-menu
            ref="elMenu"
            class="el-menu-vertical"
            router
            mode="vertical"
            :collapse="isCollapse"
          >
            <!-- <el-menu-item index="/SystemManage" key="/SystemManage">
              <i class="el-icon-data-line"></i>
              <template #title>
                <span>概览</span>
              </template>
            </el-menu-item> -->
            <template v-for="(item, index) in sysroutes">
              <template v-if="!item.meta.hidden && inRoles(item.meta.roles)">
                <el-menu-item
                  :index="$router.resolve(item.path).href"
                  v-if="!item.children"
                  :key="item.path"
                >
                  <i :class="item.meta.icon"></i>
                  <template #title>
                    <span>{{ item.meta.title }}</span>
                  </template>
                </el-menu-item>
                <el-submenu
                  :index="'sm-' + index"
                  v-else-if="item.children.length > 1"
                  :key="item.path"
                >
                  <template #title>
                    <i :class="item.meta.icon"></i>
                    <span>{{ item.meta.title }}</span>
                  </template>
                  <template v-for="(child, cindex) in item.children">
                    <template
                      v-if="!child.meta.hidden && inRoles(child.meta.roles)"
                    >
                      <el-menu-item
                        :index="$router.resolve(child.path).href"
                        v-if="!child.children"
                        :key="child.path"
                      >
                        <i :class="child.meta.icon"></i>
                        <span>{{ child.meta.title }}</span>
                      </el-menu-item>
                      <el-submenu
                        :index="'sm' + index + '-' + cindex"
                        v-else
                        :key="child.path"
                      >
                        <template #title>
                          <i :class="child.meta.icon"></i>
                          <span>{{ child.meta.title }}</span>
                        </template>
                        <template v-for="subChild in child.children">
                          <el-menu-item
                            :index="$router.resolve(subChild.path).href"
                            :key="subChild.path"
                            v-if="
                              !subChild.meta.hidden &&
                              inRoles(subChild.meta.roles)
                            "
                            :route="
                              subChild.props
                                ? { name: subChild.name, params: {} }
                                : { path: subChild.path }
                            "
                          >
                            <i :class="subChild.meta.icon"></i>
                            <span>{{ subChild.meta.title }}</span>
                          </el-menu-item>
                        </template>
                      </el-submenu>
                    </template>
                  </template>
                </el-submenu>
                <el-menu-item
                  :index="item.path"
                  v-else-if="item.children.length == 1"
                  :key="item.path"
                >
                  <i :class="item.meta.icon"></i>
                  <template #title>
                    <span>{{ item.meta.title }}</span>
                  </template>
                </el-menu-item>
              </template>
            </template>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-main>
        <Crumbs></Crumbs>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { defineComponent, provide, ref } from "vue"
import Header from "@/views/layout/Header.vue"
import Crumbs from "@/views/layout/header/Crumbs.vue"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import systemManage from "@/router/modules/systemManage"
export default defineComponent({
  components: {
    CHeader: Header,
    Crumbs,
  },
  setup() {
    const store = useStore()
    const user = store.getters.user
    const $router = useRouter()
    const isCollapse = ref(true)
    const sysroutes = $router
      .getRoutes()
      .filter((o) => o.name == "SystemManageIndex")[0].children

   // console.log($router.getRoutes())
    provide("user", user)
    return {
      inRoles(roles) {
        if (roles == undefined) return true
        return roles.filter((p) => user.roles.some((d) => d == p)).length > 0
      },
      sysroutes,
      isCollapse,
    }
  },
})
</script>

<style lang="scss"  scoped>
.container {
  min-height: calc(100% - 152px);
}
.el-menu-vertical:not(.el-menu--collapse) {
  width: 240px;
}
aside.sider {
  z-index: 3;
  // background: #fff url("../../assets/images/SiderBg.jpg") 0 100% no-repeat;
  background: #fff ;
  transition: all 0.12s linear;

  .scrollbar {
    // height: calc(100% - 60px);
  }
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>

const session = {
  get(key, isParse = false) {
    return isParse
      ? JSON.parse(sessionStorage.getItem(key))
      : sessionStorage.getItem(key);
  },

  set(key, item, isStringfy = false) {
    sessionStorage.setItem(key, isStringfy ? JSON.stringify(item) : item);
  },
  remove(key) {
    sessionStorage.removeItem(key);
  }
};

const local = {
  get(key, isParse = false) {
    return isParse
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key);
  },

  set(key, item, isStringfy = false) {
    localStorage.setItem(key, isStringfy ? JSON.stringify(item) : item);
  },
  remove(key) {
    localStorage.removeItem(key);
  }
};

export { session, local };

import { RouteRecordRaw } from "vue-router";
import Layout from "@/views/layout/Index";
import roles from "@/types/roles";
const router: RouteRecordRaw = {
  path: "/CodeFile",
  component: Layout,
  name: "CodeFileIndex",
  meta: {
    title: "批文库",
    topMenu: true,
    icon: "el-icon-document-copy",
    roles: [roles.applyUnit, roles.city, roles.prov],
  },

  children: [
    {
      path: "",
      meta: { title: "批文清单" },
      component: () => import("@/views/officialDoc/index"),
    },
  ],
};

export default router;

<template>
  <router-view v-if="RouterState" />
</template>

<script>
export default {
  provide() {
    return {
      $reload: this.reload,
    }
  },
  name: "App",
  data() {
    return {
      RouterState: true,
    }
  },
  methods: {
    reload() {
      this.RouterState = false
      this.$nextTick(() => {
        this.RouterState = true
      })
    },
  },
}
</script>

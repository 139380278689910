<template>
  <Menu />
</template>

<script>
import Menu from "./sider/Menu.vue";

export default {
  name: "sider",
  components: {
    Menu,
  },
};
</script>
<style scoped>
  
</style>

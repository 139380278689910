export default {
  admin: "管理员",
  prov: "省级用户",
  city: "市级用户",
  applyUnit: "申报单位",
  applyBelongToProv: "省直属单位",
  fundCountyManager: "财政直管县管理单位",
  reviewUnit: "机构管理账号",
  reviewUnitExpert: "机构专家",
  userManage: "用户管理",
  apply: "申报",
  review: "审核",
  giveProjectFund: "资金安排",
};

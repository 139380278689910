<template>
  <header class="header">
    <div class="clearfix" style="height:90px">
      <Logo></Logo>
      <User />
      <Notice />
    </div>
    <Sider />
  </header>
</template>

<script>
import Logo from "./sider/Logo.vue";
import Notice from "./header/Notice.vue";
import User from "./header/User.vue";
import Sider from "@/views/layout/Sider.vue";
export default {
  name: "CHeader",
  components: {
    //Crumbs,
    Notice,
    //Trigger,
    User,
    Sider,
    Logo,
  },
};
</script>

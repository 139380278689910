import { createStore } from "vuex";
import getters from "./getters.ts";
import permission from "./modules/permission.js";
import user from "./modules/user.js";
export default createStore({
  modules: {
    permission,
    user
  },
  getters
});

<template>
  <div class="notice">
    <el-popover placement="bottom" width="320" trigger="click">
      <template #reference>
        <el-badge :value="0" class="item">
          <i class="el-icon-message-solid"></i>
        </el-badge>
      </template>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "notice",
}
</script>

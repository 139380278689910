<template>
  <el-breadcrumb separator="/" class="bread-crumbs">
    <el-breadcrumb-item
      v-for="item in $route.matched"
      :key="item.path"
      :to="
        item.redirect == $router.currentRoute._rawValue.path ? null : item.path
      "
    >
      {{ typeof item.meta.title == "function" ? item.meta.title() : item.meta.title  }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>
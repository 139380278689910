import { isvalidatemobile } from "@/utils/validate";
import { Ref, watch, ref, onMounted } from "vue";

const validateCity = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请选择城市"));
  } else {
    // if (this.ruleForm.checkPass !== "") {
    //   this.$refs.ruleForm.validateField("checkPass");
    // }
    callback();
  }
};

function validatePhone(rule, value, callback) {
  const [result, msg] = isvalidatemobile(value);
  if (result) {
    callback(new Error(msg));
  } else {
    callback();
  }
}

const applyUnitRegister = {
  name: [
    { required: true, message: "请输入名称", trigger: "blur" },
    { min: 3, message: "长度不小于3个字符", trigger: "blur" },
  ],
  unitcode: [
    {
      required: true,
      message: "代码长度应该为18位",
      trigger: "blur",
    },
    { min: 18, max: 18, message: "代码长度应该为18位", trigger: "blur" },
  ],
  city: [
    {
      required: true,
      message: "请选择城市及区县",
      trigger: "change",
      validator: validateCity,
    },
  ],
  phoneNumber: [
    {
      required: true,
      message: "请输入手机号",
      trigger: "blur",
    },
    {
      trigger: "blur",
      validator: validatePhone,
    },
  ],
  email: [
    {
      type: "email",
      required: true,
      message: "格式输入不正确！",
      trigger: "blur",
    },
  ],
};

export function useRegisterFormRules(refForm: Ref<any>) {
  const rules = ref({});

  watch(rules, () => {
    setTimeout(() => {
      refForm.value.clearValidate();
    }, 1);
  });
  onMounted(() => {
    refForm.value.validate;
    const reg: any = refForm.value.model;
    rules.value = {
      ...applyUnitRegister,
      safecode: [
        {
          required: true,
          trigger: "blur",
          message: "验证码填写不正确",
        },
        {
          validator: (rule, value, callback) => {
            //isValid.value = true;
            callback();
            //验证码验证
            //   api
            //     .validateSafeCode(
            //       "changePhone",
            //       this.changePhone.phoneNumber,
            //       this.changePhone.safecode
            //     )
            //     .then(() => {
            //       if (!this.changePhone.valid) {
            //         this.changePhone.valid = true;
            //         this.changePhone.safecode = "";
            //         this.changePhone.phoneNumber = "";
            //         this.codetxt = "发送验证码";
            //         this.codedisabled = false;
            //       }

            //       callback();
            //     })
            //     .catch(() => {
            //       callback(new Error("验证码填写不正确"));
            //     });
          },
          trigger: "blur",
        },
      ],
      confirmPassword: [
        {
          required: true,
          trigger: "blur",
          message: "确认密码填写不正确",
          validator: (rule, value, callback) => {
            if (value === "" || value == undefined) {
              callback(new Error("请再次输入密码"));
            } else if (value !== reg.password) {
              callback(new Error("两次输入密码不一致!"));
            } else {
              callback();
            }
          },
        },
      ],
      password: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (value === "" || value == undefined) {
              return callback(new Error("请输入密码"));
            } else {
              let regex = /(1234|2345|3456|4567|5678|6789)/;
              if (regex.test(value)) {
                callback(new Error("密码强度太低，请不要使用连续数字"));
                return;
              }
              regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/;
              if (!regex.test(value)) {
                callback(
                  new Error(
                    "请使用数字和字母组成，并且要同时含有数字和字母，且长度要在6-16位之间"
                  )
                );
                return;
              }

              if (reg.password !== "") {
                refForm.value.validateField("confirmPassword");
              }
              callback();
            }
          },
        },
        {
          min: 6,
          max: 16,
          message: "密码长度为6位到16位之间",
          trigger: "blur",
        },
      ],
    };
  });

  return rules;
}

export const baseRule = {
  name: [
    { required: true, message: "请输入名称", trigger: "blur" },
    { min: 3, message: "长度不小于3个字符", trigger: "blur" },
  ],
  cellPhone: [
    {
      required: true,
      message: "请输入手机号",
      trigger: "blur",
    },
    {
      trigger: "blur",
      validator: validatePhone,
    },
  ],
};

export const userEditFormRules = (model) => {
  return Object.assign(
    {
      userType_Id: [
        { required: true, message: "请选择用户类型", trigger: "change" },
      ],
      loginAccount: [
        { required: true, message: "请输入登录账号", trigger: "blur" },
        { min: 3, message: "长度不小于6个字符", trigger: "blur" },
      ],
      city_ID: [
        {
          required: true,
          message: "请选择市级",
          trigger: "change",
          validator: (rule, value, callback) => {
            if ([1, 2, 3, 4, 5, 6, 10, 11].indexOf(model.userType_Id) == -1) {
              if (value === "" || value == null) {
                callback(new Error(rule.message));
              }
            }
            callback();
          },
        },
      ],
      county_ID: [
        {
          required: true,
          message: "请选择区县",
          trigger: "change",
          validator: (rule, value, callback) => {
            if ([7, 9].indexOf(model.userType_Id) > -1) {
              if (value === "" || value == null) {
                callback(new Error(rule.message));
              }
            }
            callback();
          },
        },
      ],
    },
    baseRule
  );
};
